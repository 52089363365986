export const consentAndAuthMessage = {
  giotrif: {
    body:
      "Boehringer Ingelheim (Philippines), Inc. through its service provider (MedGrocer) has developed the HOPE Program (“Program”), a program that provides patients with medicine assistance for medicines covered by the program and educational support on the disease and therapy prescribed by their doctors. By accepting the Terms and Conditions of the Program, the patient confirms that they understand the following:",
    fields: [
      "MG Health Solutions Inc. (“MedGrocer”) is the authorized service provider of Boehringer Ingelheim (Philippines), Inc. (“BIPHI”) in the implementation of the Program.",
      "To avail of the benefits of the Program, the patient should enroll by accomplishing the online Patient Enrollment Form. They will receive an SMS confirming eligibility to the program by the next working day. Once enrolled, they should also apply for medicine assistance by completing the online Medicine Assistance Application Form. They will receive an SMS confirming their medicine assistance in 2-5 working days. Eligibility to the program is subject to approval by MedGrocer.",
      "Membership in the Program is not transferable.",
      "The information disclosed in any of the forms, as well as information obtained in the course of the Program, is collected, processed, and stored in a database which shall be used and administered solely by the MedGrocer and its partners. The information referred to in this section may be disclosed to the patient’s attending doctor, MedGrocer, MedGrocer’s partners, and third parties for any purpose that may include monitoring and following up on patient compliance with the prescription as well as improving the Program.",
      "Any patient-specific information may not be used in any form of publication or promotional material without prior written approval by the patient. Anonymized and aggregate patient data may be analyzed and used to provide basis to further improve the Program.",
      "Any information relayed about the patient’s medical condition, importance of therapy, method of administration, precautions, and special instructions is for informational purposes only and not meant to replace the professional advice of the patient’s doctor.",
      "By availing of the benefits granted by the program, patient consents to receiving mail, email, calls, text messages, and other means of communication from MedGrocer and its partners. By applicable regulations, they may decline at any time upon notification to MedGrocer and its partners.",
      "Patient will comply with all guidelines of the Program to enjoy its benefits; otherwise, membership in the Program may be terminated.",
      "The medicine assistance that will be provided is subject to evaluation by MedGrocer and approval by BIPHI and can be changed depending on BIPHI’s decision. The results of the medicine assistance should be treated with confidentiality.",
      "BIPHI reserves the right to terminate any of the benefits available under the Program or the Program itself at any time without prior notice. The benefits may be availed of only during the duration of the Program. Subject to BIPHI's discretion and with written instructions from BIPHI to MedGrocer, the program may be stopped or discontinued, in full or in part. BIPHI reserves the right to change the Program, its mechanics and benefits, including these Terms and Conditions, in full or in part, any time.",
      "BIPHI, through its service provider MedGrocer, is obliged to collect details of any adverse events or product quality complaints that the patient may experience during the conduct of the Program and throughout medication. Should an adverse event or product complaint be identified, patient agrees that BIPHI and/or MedGrocer will collect this information from either the patient or their doctor for safety reporting purposes only.",
      "MedGrocer and its partners shall not be responsible nor liable to patient for any loss or damage incurred or suffered as a consequence of any delay or inability of MedGrocer to perform any of its obligations pursuant to these Terms and Conditions due to any telecommunications failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery.",
      "To the extent allowed by law and applicable regulations, MedGrocer and its partners shall verify the identity of prescribing doctors indicated in the forms. In the event that a prescribing doctor or the information relating to the prescribing doctor is found to be fictitious or false, MedGrocer reserves the right to cancel the patient’s membership to the Program including all its benefits.",
    ],
    subBody:
      "The patient hereby represents and warrants that all personal information provided/will provide in connection with their application in this Program is correct, accurate, and true. The patient confirms that their participation in this Program is voluntary and knows that they are free to withdraw at any time based on their own decision or as recommended by their doctor and as such, they will inform MedGrocer of their withdrawal by contacting us <a href='https://bi-hope.medgrocer.com/giotrif/help-center/#contact-us'>here</a>.",
  },
  hope: {
    body:
      "Boehringer Ingelheim (Philippines), Inc. (“BIPHI”) and MedGrocer have developed the Holistic Patient Expansion (“HOPE”) Program, a program that provides patients with financial assistance for medicines covered in the program and educational support on the disease and therapy prescribed by their doctors. By accepting the Terms and Conditions of the Program, the patient confirms that they understand the following:",
    fields: [
      "MG Health Solutions Inc. (“MedGrocer”) is the authorized representative of BIPHI in the implementation of the program.",
      "To avail of the benefits of the program, the patient should enroll by accomplishing the online enrollment form. They will receive an SMS confirming eligibility to the program within one working day. Once enrolled, they should also apply for financial assistance by completing the online financial assessment form. Eligibility to the program is subject to approval by BIPHI and MedGrocer.",
      "To the extent allowed by law and applicable regulations, MedGrocer and its partners shall verify the identity of prescribing doctors indicated in the forms. In the event that a prescribing doctor or the information relating to the prescribing doctor is found to be fictitious or false, MedGrocer reserves the right to cancel the patient’s membership to the program including all its benefits.",
      "The financial assistance that will be provided is subject to a financial evaluation by MedGrocer and approval by BIPHI and can be changed depending on BIPHI’s decision. The results of the financial assistance should be treated with confidentiality.",
      "By availing of the benefits granted by the program, the patient consents to receiving mail, email, calls, text messages, and other means of communication from MedGrocer and its partners. By applicable regulations, they may decline at any time upon notification to MedGrocer and its partners.",
      "The information disclosed in any of the forms, as well as information obtained in the course of the program, is collected, processed, and stored in a database which shall be used and administered solely by the MedGrocer and its partners. The information referred to in this section may be disclosed to the patient’s attending doctor, MedGrocer, MedGrocer’s partners, and third parties for any purpose that may include monitoring and following up on patient compliance with the prescription as well as improving the program.",
      "Any patient-specific information may not be used in any form of publication or promotional material without prior written approval by the patient. Anonymized and aggregate patient data may be analyzed and used to provide basis to further improve the program.",
      "Any information relayed about the patient’s medical condition, importance of therapy, method of administration, precautions, and special instructions is for informational purposes only and not meant to replace the professional advice of the patient’s doctor.",
      `The patient will comply with all guidelines of the program to enjoy its benefits; otherwise, membership in the program may be terminated.`,
      "Membership in the program is not transferable.",
      "BIPHI reserves the right to terminate any of the benefits available under the program or the program itself at any time without prior notice. The benefits to the program may be availed of for the duration that MedGrocer and BIPHI offers the same. MedGrocer or BIPHI may opt to stop or continue the program, in full or in part, at any time at its own discretion. Both parties also reserve the right to change the program, its mechanics and benefits, and these Terms and Conditions, in full or in part, any time.",
      "BIPHI and MedGrocer are obliged to collect details of any adverse events or product quality complaints that the patient may experience during the conduct of the program and throughout medication. If an adverse event or product complaint is identified, patient agrees that BIPHI or MedGrocer will collect this information from either the patient or their doctor for safety reporting purposes only.",
      "MedGrocer and its partners shall not be responsible nor liable to patient for any loss or damage incurred or suffered as a consequence of any delay or inability of MedGrocer to perform any of its obligations pursuant to these Terms and Conditions due to any telecommunications failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery.",
    ],
    subBody: `The patient hereby represents and warrants that all personal information provided in connection with their application in the program is correct, accurate, and true. The patient confirms that their participation in the program is voluntary and knows that they are free to withdraw at any time based on their own decision or as recommended by their doctor and as such, they will inform MedGrocer of their withdrawal by contacting <span class="has-text-primary has-text-weight-bold">0917 814 0122</span> or emailing <a class="has-text-primary has-text-weight-bold" href="mailto:bi-hope@medgrocer.com">bi-hope@medgrocer.com</a>.`,
  },
}
