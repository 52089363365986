import React, { useContext } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Button from "elements/Button"
import Section from "elements/Section"
import FormCheckbox from "elements/Form/FormCheckbox"

import styles from "./utils/elements.module.scss"
import { AppContext } from "../../context/AppContext"
import { consentAndAuth } from "../../context/AppReducer"
import { validationSchema } from "./utils/consentAndAuthSchema"
import { consentAndAuthMessage } from "./utils/consentAndAuthMessage"

const termsAndConditionBlurb = (
  <span>I agree to the terms and conditions above.</span>
)

const ConsentAndAuthorization = ({
  app,
  cta,
  children,
  ctaClassNames,
  hasActionLinks,
  handleOnSubmit,
}) => {
  const { state, dispatch } = useContext(AppContext)

  return (
    <Section title="Consent and Authorization" isRequired>
      <div className="mx-2 mx-0-mobile">
        <Formik
          initialValues={{ agreeToConsent: state.consentAndAuth }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <article className="message">
                <div
                  className={classNames("message-body", styles["collapsible"])}
                >
                  <section className="content">
                    <div>
                      <b>HOPE Program Patient Consent Form</b>
                      <p className="is-size-6 mt-1">
                        {consentAndAuthMessage[app]?.body}
                      </p>
                      <div className="my-1 content">
                        <ol>
                          {consentAndAuthMessage[app]?.fields.map(field => (
                            <li
                              dangerouslySetInnerHTML={{ __html: field }}
                            ></li>
                          ))}
                        </ol>
                      </div>
                      <p
                        className="is-size-6 mt-1"
                        dangerouslySetInnerHTML={{
                          __html: consentAndAuthMessage[app]?.subBody,
                        }}
                      />
                    </div>
                  </section>
                </div>
              </article>
              <FormCheckbox
                name="agreeToConsent"
                values={values.agreeToConsent}
                options={[termsAndConditionBlurb]}
                onChange={event =>
                  dispatch({
                    type: consentAndAuth.SAVE_CONSENT_AND_AUTH,
                    payload: event.target.checked
                      ? [termsAndConditionBlurb]
                      : [],
                  })
                }
              />
              {app === "hope" && hasActionLinks && (
                <Button
                  type="submit"
                  color="primary"
                  className={classNames("mt-2 mb-1 is-medium", ctaClassNames)}
                >
                  {cta}
                </Button>
              )}
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
