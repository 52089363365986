import React, { useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Layout from "../Layout/Layout"
import FormAddons from "./FormAddons"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"

import { setEncryptedKey } from "./services/keys"
import { AppContext } from "../../context/AppContext"

const EnrollmentMechanics = props => {
  const {
    cta,
    nextPath,
    backPath,
    module,
    mechanics,
    addon,
  } = props.pageContext
  const { state, dispatch } = useContext(AppContext)

  const handleFormSubmit = values => {
    setEncryptedKey(`${module.app}-${module.name}`)
    dispatch({
      type: "SAVE_ACKNOWLEDGEDMENT",
      payload: values.hasAcknowledge,
    })

    if (module.name === "medicine-assistance") {
      if (state.isNewPatient === "I am a new patient")
        navigate(`/${nextPath.newNextPath}`)
      else {
        navigate(`/${nextPath.existingNextPath}`)
      }
    } else {
      navigate(`/${nextPath}`)
    }
  }

  return (
    <Layout
      title={module.title}
      subtitle={module.subtitle}
      seoTitle={`${module.seoTitle} Mechanics`}
    >
      <Container isCentered>
        <div
          className="mb-2"
          dangerouslySetInnerHTML={{
            __html: mechanics?.content?.childMarkdownRemark?.html,
          }}
        ></div>
        <FormAddons addon={addon} />
        {!module.hasNoConsentAndAuth && (
          <ConsentAndAuthorization
            module={module.name}
            app={module.app}
            hasActionLinks={false}
            handleOnSubmit={handleFormSubmit}
          >
            <ActionButtons
              back={{
                label: "Back",
                link: "/",
              }}
              submit={{
                label: cta,
                disabled: state?.consentAndAuth.length === 0,
              }}
            />
          </ConsentAndAuthorization>
        )}
        {module.hasNoConsentAndAuth && (
          <ActionButtons
            back={{
              label: "Back",
              link: backPath || "/",
            }}
            next={{
              label: cta,
              link: `/${nextPath}`,
            }}
          />
        )}
        {module.subtitle && (
          <div className={classNames("is-flex is-justify-content-center mt-1")}>
            <section
              dangerouslySetInnerHTML={{ __html: module.ctaSubtitle }}
            ></section>
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
